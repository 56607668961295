<template>
 <v-container>
     <v-row>
         <v-col lg=6>
             <s-crud title="Ubicaciones" height="220" add edit remove excel :filter="ZncID" :config=configZones @save=save($event) @rowSelected=rowSelectedZones($event) >
<template scope="props">
    <v-container>
        <v-row>
            <v-col  lg=12  class="s-col-form"> <s-text autofocus label="Ubicación" v-model=props.item.ZncName ></s-text>   </v-col>         
              <v-col lg=7 class="s-col-form"> <s-select-area centercost full label="Centro de Costo" v-model=props.item.CceID></s-select-area>  </v-col>
                <v-col class="s-col-form"> <s-select-definition :def=1152 label="Estado" v-model=props.item.ZncStatus></s-select-definition>  </v-col>
        </v-row>
    </v-container>
</template>
 <template v-slot:ZncStatus="{ row }">
            <v-chip  x-small :color="row.ZncStatus == 1 ? 'success' : 'error'" >
              {{ row.ZncStatus == 1 ? "Activo" : "Baja" }}
            </v-chip>
          </template>
             </s-crud>
         </v-col>
              <v-col lg=6>
             <s-crud height="220" v-if='filterSystem.ZncID!=null' title="Sistemas" add edit remove :config=configSystem
              @save=saveSystem($event) :filter=filterSystem @rowSelected=rowSelectedSystem($event)>
<template scope="props">
    <v-container>
        <v-row>
            <v-col class="s-col-form"> <s-text label="Sistema" autofocus v-model=props.item.SysName></s-text>   </v-col>
               <v-col class="s-col-form"> <s-select-definition :def=1153 label="Estado" v-model=props.item.SysStatus></s-select-definition>  </v-col>
        </v-row>
    </v-container>
</template>
 <template v-slot:SysStatus="{ row }">
            <v-chip  x-small :color="row.SysStatus == 1 ? 'success' : 'error'" >
              {{ row.SysStatus == 1 ? "Activo" : "Baja" }}
            </v-chip>
          </template>
             </s-crud>
         </v-col>
             
             <v-col lg=12>
             <s-crud height="170" title="Componentes" add edit remove :config=configSystemComponent @save=saveComponent($event)
             v-if='filterSystemComponent.SysID!=null' :filter=filterSystemComponent>
<template scope="props">
    <v-container>
        <v-row>
            <v-col class="s-col-form"> <s-text label="Componente" autofocus v-model=props.item.ComName></s-text>   </v-col>
          <v-col class="s-col-form"> <s-select-definition :def=1154 label="Estado" v-model=props.item.ComStatus></s-select-definition>  </v-col>
        </v-row>
    </v-container>
</template>
 <template v-slot:ComStatus="{ row }">
            <v-chip  x-small :color="row.ComStatus == 1 ? 'success' : 'error'" >
              {{ row.ComStatus == 1 ? "Activo" : "Baja" }}
            </v-chip>
          </template>
             </s-crud>
         </v-col>
     </v-row>
 </v-container>
</template>

<script>
import _sSystem from "@/services/General/SystemService.js";
import _sSystemComponent from "@/services/General/SystemComponentService.js";
import _sZoneCompany from "@/services/General/ZoneCompanyService.js";

export default {
  name: "RequirementService",
  components: {},
  props: {},

  data: () =>({
    ZncID : {},
    configZones: {
      model: {
        ZncID: "ID",ZncName:'',ZncStatus:''
      },
      service: _sZoneCompany,
      headers: [
        { text: "ID", value: "ZncID", sortable: false, width: 10 },
        { text: "Nombre", value: "ZncName", sortable: false },
        { text: "Centro de Costo", value: "CceName", sortable: false },
        { text: "Estado", value: "ZncStatus", sortable: false },
      ],
    }, 
    filterSystem:{ZncID:0},
    configSystem: {
      model: {
        SysID: "ID",SysStatus:''
      },
      service: _sSystem,
      headers: [
         { text: "ID", value: "SysID", sortable: false, width: 10 },
        { text: "Nombre", value: "SysName", sortable: false },
        { text: "Estado", value: "SysStatus", sortable: false },
      ],
    },
     filterSystemComponent:{SysID:0},
     configSystemComponent: {
      model: {
        ComID: "ID",ComStatus:''
      },
      service:_sSystemComponent,
      headers: [
         { text: "ID", value: "ComID", sortable: false, width: 10 },
        { text: "Nombre", value: "ComName", sortable: false },
        { text: "Estado", value: "ComStatus", sortable: false },
      ],
    },
  }),
  methods: {
      rowSelectedZones(items){
          if(items.length>0){
              this.filterSystem={ZncID:items[0].ZncID};
          }else this.filterSystem={SysID:null};
      },
       rowSelectedSystem(items){
          if(items.length>0){
              this.filterSystemComponent={SysID:items[0].SysID};
          }else this.filterSystemComponent={SysID:null};
      },
      save(item){
          if(item.ZncName.trim().lenght<1) {this.$fun.alert("Complete Nombre ","warning");return}
            if(item.CceID==null) {this.$fun.alert("Complete Centro de Costo ","warning");return}
          item.save();},
      saveSystem(item){
          if(item.SysName.trim().lenght<1) {this.$fun.alert("Complete Nombre ","warning");return}
          item.ZncID=this.filterSystem.ZncID;
          item.save();},
      saveComponent(item){ 
          if(item.ComName.trim().lenght<1) {this.$fun.alert("Complete Nombre ","warning");return}
          item.SysID=this.filterSystemComponent.SysID;
          item.save();},
    dobleClick(item) {
      this.$emit("dblClick", item);
    },
  },
  created() {},
};
</script>