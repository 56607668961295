import Service from "../Service";

const resource = "system/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    excel(cmp, requestID) {
        return Service.post(resource + "reportexcel", cmp, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
};